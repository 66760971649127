import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import ContactLink from '../ContactLink';
import { mq, colors } from '../../styles/theme';
import { getImage } from 'gatsby-plugin-image'
import useWindowSizeChange from '../../hooks/useWindowSizeChange';

const FeaturedContentRow = ({ heading, copy, image, mobileImage, cta, contact, orientation }) => {

  const gatsbyImage = getImage(image)
  const featuredMobileImage = getImage(mobileImage)

  const currentImage = !useWindowSizeChange(1024) ? gatsbyImage : featuredMobileImage

  return (
    <Section image={currentImage?.images?.fallback?.src || gatsbyImage?.images?.fallback?.src} orientation={orientation}>
      <ContentContainer>
        <Heading>{heading}</Heading>
        {copy && <Copy>{copy}</Copy>}
        {cta?.text && <Button text={cta?.text} link={cta?.link.search("entity:node") != -1 ? (cta?.link.search("/") != -1 ? `/questionnaire${cta?.link.slice(cta?.link.search("/"))}` : '/') : cta?.link}/>}
        {contact?.phone && <ContactLink phone={contact?.phone} text={contact?.text}/>}
      </ContentContainer>
    </Section>
  )
};

export default FeaturedContentRow;

const Section = styled.section`
  background: url(${props => props.image}) no-repeat center center / cover;
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.orientation === 'left' ? 'flex-start' : 'flex-end'};
  min-block-size: 582px;
  padding-inline: 20px;
  width: 100%;

  ${mq.desktop} {
    align-items: center;
    flex-wrap: nowrap;
    min-block-size: 500px;
    padding-block: 60px;
    padding-inline: 0;
    padding-inline-start: ${props => props.orientation === 'left' ? '40px' : '0'};
    padding-inline-end: ${props => props.orientation === 'left' ? '0' : '40px'};
  }

  ${mq.wide} {
    margin-inline: auto;
    max-inline-size: 1440px;
  }
`

const ContentContainer = styled.div`
  align-items: flex-end;
  background-color: ${colors.white};
  min-block-size: 335px;
  max-inline-size: 750px;
  margin-block-end: 25px;
  padding: 25px;

  ${mq.desktop} {
    min-block-size: 390px;
    margin-block-end: 0;
    padding: 70px;
  }
`

const Heading = styled.h2`
  color: ${colors.black};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  margin-block-end: 10px;

  ${mq.desktop} {
    font-size: 40px;
    line-height: 42px;
  }
`

const Copy = styled.p`
  font-size: 14px;
  margin-block-end: 20px;

  ${mq.desktop} {
    font-size: 16px;
  }
`
