import { useEffect, useState } from 'react';

const useWindowSizeChange = (size = 768) => {
  let defaultWidth

  if (typeof window !== 'undefined') {
    defaultWidth = window.innerWidth
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  const [width, setWidth] = useState(defaultWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= size;
};

export default useWindowSizeChange;
