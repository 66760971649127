import React from 'react'
import styled from 'styled-components';
import { colors, mq } from '../../styles/theme';

const Button = ({ text, link, type, isSubmit, disabled, className, target }) => {
  return (
    <>
      {
        isSubmit ?
          <SubmitButton className={className} type='submit' disabled={disabled ? disabled : false}>
            {text}
          </SubmitButton>
          :
          <ButtonPrimitive className={className} href={type === 'email' ? `mailto:${link}` : link} target={target || '_self'}>
            {text}
          </ButtonPrimitive>
      }
    </>
  )
}

export default Button;

const ButtonPrimitive = styled.a`
  background: linear-gradient(to left, #4ba492, #26686d);
  color: ${colors.white};
  display: inline-block;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    background: linear-gradient(to right, #4ba492, #26686d);
  }

  ${mq.desktop} {
    text-align: inherit;
    width: inherit;
  }
`

const SubmitButton = styled.button`
  background: ${props => props.disabled ? '#bcbdbd' : 'linear-gradient(to left, #4ba492, #26686d)'};
  border: none;
  color: ${props => props.disabled ? '#636566' : colors.white};
  cursor: pointer;
  display: inline-block;
  margin-block-start: 20px;
  padding: 15px 25px;
  text-decoration: none;

  &:hover {
    background: ${props => !props.disabled && 'linear-gradient(to right, #4ba492, #26686d)'};
  }

  ${mq.desktop} {
    text-align: center;
    width: 100%;
  }
`
