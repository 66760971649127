import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors, mq } from '../styles/theme'
import OrbImage from '../components/OrbImage';
import Button from '../components/Button';
import Layout from '../components/Layout/layout';
import FeaturedContentRow from '../components/FeaturedContentRow';
import InfiniteScroll from 'react-infinite-scroll-component';
import filter from '../assets/filter.svg';
import Rtf from '../components/Rtf/Article';
import Preloader from '../components/Preloader';
import { graphql } from 'gatsby';
import { getFacetedResources } from '../services/resources';

const ResourceListing = ({ pageContext, data }) => {

  const { PageHeading, PageBody } = pageContext

  const {
    allTaxonomyTermResourceAudience,
    allTaxonomyTermResourceContentTypes,
    allTaxonomyTermResourceGoalsTopics,
    allTaxonomyTermResourceTopics,
    allParagraphFeaturedContentRowWithCta,
    paragraphRelatedResources,
    allNodeResource
  } = data

  const relatedResourcesList = paragraphRelatedResources?.relationships?.field_manually_selected_resource
  const resourceElements = relatedResourcesList?.length > 0 ? relatedResourcesList : allNodeResource?.nodes

  const audienceOptions = []
  const goalOptions = []
  const contentTypeOptions = []
  const resourceOptions = []

  let currentUrl = ''

  if (typeof window !== 'undefined'){
    currentUrl = new URLSearchParams(window.location.search);
  }

  const facetInfo = [
    {
      query: allTaxonomyTermResourceAudience.nodes,
      list: audienceOptions
    },
    {
      query: allTaxonomyTermResourceGoalsTopics.nodes,
      list: goalOptions
    },
    {
      query: allTaxonomyTermResourceContentTypes.nodes,
      list: contentTypeOptions
    },
    {
      query: allTaxonomyTermResourceTopics.nodes,
      list: resourceOptions
    }
  ]

  // Populates facet options based on static queries
  const useOptionPopulate = (nodeList, optionList) => {
    nodeList.forEach(el => {
      optionList.push({
        id: el.drupal_internal__tid.toString(),
        name: el.name
      })
    })
  }

  facetInfo.map(el => (
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOptionPopulate(el.query, el.list)
  ))

  const resourceFacets = [
    {
      id: 'audiences',
      name: 'Audiences',
      options: audienceOptions
    },
    {
      id: 'goal_topics',
      name: 'Goal Topics',
      options: goalOptions
    },
    {
      id: 'content_types',
      name: 'Content Types',
      options: contentTypeOptions
    },
    {
      id: 'resource_topics',
      name: 'Resource Topics',
      options: resourceOptions
    }
  ]

  const [resources, setResources] = useState([]);
  const [filters, setFilters] = useState({});
  const [tmpFilters, setTmpFilters] = useState({});
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const resourcesList = useRef(null);
  const [loadMore, setLoadMore] = useState(false);
  const [pager, setPager] = useState({
    page: 1,
    items_per_page: 0,
    total_results_count: 0,
    total_pages: 0
  });
  const [loadingResources, setLoadingResources] = useState(true);

  const getUrlParams = () => {
    const entries = currentUrl.entries();
    const params = Object.fromEntries(entries);

    Object.keys(params).forEach(el => {
      const from = currentUrl.get(el)

      if (from){
        const values = from.split(',')
        values.forEach(filterVal => {
          setFilters(prevFilters => {
            return { ...updateFiltersStatus(prevFilters, el, filterVal) }
          })
        })
      }
    })
  }

  const setUrlParamFilters = () => {
    resourceFacets.forEach(el => {
      if (el.id in filters){
        currentUrl.set(el.id, filters[el.id])
        const newRelativePathQuery = `${window.location.pathname}?${currentUrl.toString()}`
        window.history.pushState(null, '', newRelativePathQuery);
      }

      if (Object.keys(filters).length === 0){
        window.history.pushState(null, '', window.location.pathname)
      }
    })
  }

  useEffect(() => {
    getResources(filters, 1).then(el => setResources(el))
    setUrlParamFilters()
  }, [filters]);


  useEffect(() => {
    getUrlParams()
  }, [])

  const updateFiltersStatus = (prevFilters, categoryId, optionId) => {
    if (prevFilters && categoryId in prevFilters) {
      if (!prevFilters[categoryId].includes(optionId)) {
        prevFilters[categoryId].push(optionId)
      } else {
        delete prevFilters[categoryId].splice(prevFilters[categoryId].indexOf(optionId), 1)
        if (!prevFilters[categoryId].length) {
          delete prevFilters[categoryId]
        }
      }
    } else {
      prevFilters[categoryId] = [optionId]
    }
    return { ...prevFilters }
  }

  const updateFiltersObj = (categoryId, optionId, type) => {
    if (type === 'mobile') {
      setTmpFilters(prevFilters => {
        return { ...updateFiltersStatus(prevFilters, categoryId, optionId) }
      })
    } else {
      setFilters(prevFilters => {
        return { ...updateFiltersStatus(prevFilters, categoryId, optionId) }
      })
    }
  }

  const getResources = async (filters, page) => {
    const resourceResults = [];
    setLoadingResources(true);

    const list = await getFacetedResources({ ...filters, page });

    const resourceList = list.resources;
    const pager = list.pager;

    setLoadMore(pager?.page < pager?.total_pages);

    setPager({
      page: pager?.page,
      total_pages: pager?.total_pages,
      total_results_count: pager?.total_results_count
    });

    resourceList?.map(resourceEl => {
      const resourceContentType = resourceEl.taxonomies.filter(contentType => contentType.type === 'resource_content_types')

      resourceResults.push({
        url: resourceEl.path,
        source: resourceEl.source,
        heading: resourceEl.title,
        type: resourceContentType[0]?.name,
        goals: resourceEl.taxonomies.filter(goal => goal.type === 'resource_goals_topics'),
        theme: resourceEl.taxonomies[0]?.theme,
        link: resourceEl?.link,
        paragraphType: resourceEl.type
      });
    });

    setLoadingResources(false);

    return resourceResults;
  }

  const loadMoreResources = async () => {
    setLoadingResources(true);
    const resource = await getResources(filters, pager.page + 1)
    setResources(prevState => {
      return [...prevState, ...resource]
    });
    setLoadingResources(false);
  }


  const goalsArray = list => {
    const goals = []

    list.map(el => {
      return goals.push(el.name)
    })
    return goals.join(' | ')
  }

  const handleFilterClick = (e, type) => {
    if (e.target.classList.contains('resources-filter-category-expand-button')) {
      let alreadyExpanded = 0;
      if (e.target.classList.contains('toggle-expand-button')) {
        alreadyExpanded = 1
      }
      const expandButtons = document.querySelectorAll('.resources-filter-category-expand-button')
      expandButtons.forEach(button => {
        button.classList.remove('toggle-expand-button')
      })

      const categories = document.querySelectorAll('.resources-filter-category-options')
      categories.forEach(category => {
        category.classList.remove('toggle-expand-body')
      })

      if (!alreadyExpanded) {
        e.target.classList.toggle('toggle-expand-button')
        const id = e.target.getAttribute('data-id')
        const categoryOptions = e.currentTarget.querySelector(`.resources-filter-category-options[data-category-id="${id}"]`)

        if (categoryOptions) {
          categoryOptions.classList.toggle('toggle-expand-body')
        }
      }
    }

    if (e.target.classList.contains('resources-filter-category-option')) {
      const categoryId = e.target.getAttribute('data-category-id')
      const objectId = e.target.getAttribute('data-id')
      updateFiltersObj(categoryId, objectId, type)
    }

    if (e.target.classList.contains('resources-filter-category-view-more')) {
      const categoryId = e.target.getAttribute('data-category-id')
      const categoryOptions = e.currentTarget.querySelectorAll(`.resources-filter-category-option[data-category-id="${categoryId}"]`)
      categoryOptions.forEach(option => {
        option.classList.remove('hidden')
      })

      e.target.classList.add('hidden')
    }
  }

  const handleAppliedFilterClick = (e) => {
    if (e.target.classList.contains('resources-filter-category-option-remove')) {
      const categoryId = e.target.getAttribute('data-category-id')
      const objectId = e.target.getAttribute('data-id')
      updateFiltersObj(categoryId, objectId)
    }
  }

  const handleClearAllFilters = () => {
    setFilters({})
    setTmpFilters({})
  }

  const handleFilterButtonClick = () => {
    setShowMobileFilters(true)
    setTmpFilters(JSON.parse(JSON.stringify(filters)))
  }

  const handleCloseFilterButtonClick = () => {
    setShowMobileFilters(false)
    setTimeout(() => {
      resourcesList.current.scrollIntoView({behavior:"smooth", block: "start"})
    },100)
    setTmpFilters(JSON.parse(JSON.stringify(filters)))
  }

  const handleShowMyResults = () => {
    setFilters(JSON.parse(JSON.stringify(tmpFilters)))
    setShowMobileFilters(false)
    setTimeout(() => {
      resourcesList.current.scrollIntoView({behavior:"smooth", block: "start"})
    },100)
  }

  const handleClearAllFiltersMobile = () => {
    setTmpFilters({})
  }

  return (
    <Layout>

      <ResourcesFilterMobile showMobileFilters={showMobileFilters}>
        <ResourcesFilterMobileHeading>
          <ResourcesFilterMobileHeadingTitle>{'Filter'}</ResourcesFilterMobileHeadingTitle>
          <ResourcesFilterMobileCloseButtonIcon onClick={handleCloseFilterButtonClick}/>
        </ResourcesFilterMobileHeading>
        <ResourcesFilters onClick={(e) => handleFilterClick(e, 'mobile')}>
          {resourceFacets && resourceFacets.map((category, i) => (
            <ResourcesFilterCategory key={i} data-id={category.id} className={'resources-filter-category'}>
              <ResourcesFilterCategoryNameWrapper>
                <ResourcesFilterCategoryName>{category.name}</ResourcesFilterCategoryName>
                <ResourcesFilterCategoryExpandButton data-id={category.id} className={`resources-filter-category-expand-button ${i === 0 ? 'toggle-expand-button' : ''}`}/>
              </ResourcesFilterCategoryNameWrapper>

              <ResourcesFilterCategoryOptions data-category-id={category.id} className={`resources-filter-category-options ${i === 0 ? 'toggle-expand-body' : ''}`}>
                {category.options && category.options.map((option, i) => (
                  <ResourcesFilterCategoryOption
                    className={`resources-filter-category-option ${i > 6 ? 'hidden' : ''} ${(tmpFilters[category.id] && tmpFilters[category.id].indexOf(option.id) !== -1) ? 'selected' : ''}`}
                    data-category-id={category.id} data-id={option.id} key={i}>{option.name}</ResourcesFilterCategoryOption>
                ))}

                {category.options.length > 7 &&
                  <ViewMoreFilters className={`resources-filter-category-view-more`} data-category-id={category.id}>View More</ViewMoreFilters>
                }

              </ResourcesFilterCategoryOptions>
            </ResourcesFilterCategory>
          ))}
        </ResourcesFilters>
        <ResourcesFilterMobileButtonsWrapper>
          <ButtonWrapper>
            <ShowMyResultsButton onClick={handleShowMyResults}>{'Show My Results'}</ShowMyResultsButton>
          </ButtonWrapper>
          <ClearAndResultsWrapper>
            <ClearAllFilters onClick={handleClearAllFiltersMobile}>Clear All</ClearAllFilters>
            <ResourcesFilterMobileResults>{resources.length} Results</ResourcesFilterMobileResults>
          </ClearAndResultsWrapper>
        </ResourcesFilterMobileButtonsWrapper>
      </ResourcesFilterMobile>

      <ResourceListingWrapper showMobileFilters={showMobileFilters}>
        <ResourceListingHeading>{PageHeading}</ResourceListingHeading>
        <ResourceListingMainContentWrapper>
          <ResourceListingMainContent>
            <Rtf copy={PageBody}/>
          </ResourceListingMainContent>
        </ResourceListingMainContentWrapper>

        <FeaturedResourceContainer>
          {resourceElements && resourceElements.map((el, i) => {
            const link = el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? `/resources${el.path.alias}` : el.relationships.field_resource.field_external_resource_link.uri
            const target = el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? '_self' : '_blank'

            return (
              <FeaturedResourceLink href={link} target={target} key={i}>
                <FeaturedResource>
                  <OrbImageWrapper>
                    <OrbImage type={el.relationships.field_goal_topic_labels?.[0]?.relationships?.field_theme?.field_theme_key}/>
                  </OrbImageWrapper>
                  <FeaturedResourceUpper>
                    <FeaturedResourceSource>
                      {el.relationships.field_resource.paragraph_type.drupal_internal__target_id === 'internal_resource' ? 'The Ohio State University' : el.relationships.field_resource.field_source}
                    </FeaturedResourceSource>
                    <FeaturedResourceHeading>{el.field_heading_plain_text}</FeaturedResourceHeading>
                  </FeaturedResourceUpper>
                  <FeaturedResourceBottom>
                    <FeaturedResourceType>{el.relationships?.field_content_types_labels?.name}</FeaturedResourceType>
                  </FeaturedResourceBottom>
                </FeaturedResource>
              </FeaturedResourceLink>
            )
          })}
        </FeaturedResourceContainer>

        <FeaturedContentRow
          orientation={allParagraphFeaturedContentRowWithCta.nodes[0].field_content_orientation === false ? 'left' : 'right'}
          contact={{ phone: allParagraphFeaturedContentRowWithCta.nodes[0].field_phone_number }}
          copy={allParagraphFeaturedContentRowWithCta.nodes[0].field_prgph_copy}
          heading={allParagraphFeaturedContentRowWithCta.nodes[0].field_prgph_heading_plain_text}
          image={allParagraphFeaturedContentRowWithCta.nodes[0].relationships.field_prgph_image.relationships.field_media_image.localFile}
          cta={
            {
              text: allParagraphFeaturedContentRowWithCta.nodes[0].field_prgph_link_tab.title,
              link: allParagraphFeaturedContentRowWithCta.nodes[0].field_prgph_link_tab.uri
            }
          }
        />

        <ResourceListingSeparator/>

        <FilteredResourcesWrapper ref={resourcesList}>

          {loadingResources && <Preloader containerHeight="100%" />}

          <ResourcesFilterButton>{'Filter'}
            <ResourcesFilterButtonIcon onClick={handleFilterButtonClick}/>
          </ResourcesFilterButton>
          <ResourcesFilterContainer>
            <ResourcesFilterHeading>Filter</ResourcesFilterHeading>
            <ResourcesFilters onClick={(e) => handleFilterClick(e, 'desktop')}>
              {resourceFacets && resourceFacets.map((category, i) => (
                  <ResourcesFilterCategory key={i} data-id={category.id} className={'resources-filter-category'}>
                    <ResourcesFilterCategoryNameWrapper>
                      <ResourcesFilterCategoryName>{category.name}</ResourcesFilterCategoryName>
                      <ResourcesFilterCategoryExpandButton data-id={category.id} className={`resources-filter-category-expand-button ${i === 0 ? 'toggle-expand-button' : ''}`}/>
                    </ResourcesFilterCategoryNameWrapper>

                    <ResourcesFilterCategoryOptions data-category-id={category.id} className={`resources-filter-category-options ${i === 0 ? 'toggle-expand-body' : ''}`}>
                      {category.options && category.options.map((option, i) => (
                          <ResourcesFilterCategoryOption
                            className={`resources-filter-category-option ${i > 6 ? 'hidden' : ''} ${(filters[category.id] && filters[category.id].indexOf(option.id) !== -1) ? 'selected' : ''}`}
                            data-category-id={category.id} data-id={option.id} key={i}>{option.name}</ResourcesFilterCategoryOption>
                        )
                      )}
                      {category.options.length > 7 &&
                        <ViewMoreFilters className={`resources-filter-category-view-more`} data-category-id={category.id}>View More</ViewMoreFilters>
                      }

                    </ResourcesFilterCategoryOptions>
                  </ResourcesFilterCategory>
                )
              )}
            </ResourcesFilters>
          </ResourcesFilterContainer>
          <FilteredResourcesContainer>
            <FilteredResourcesHeadingWrapper>
              <FilteredResourcesHeadingDefault>
                {Object.keys(filters).length == 0 && resources.length > 0 &&
                  <>Featured Resources</>
                }

                {Object.keys(filters).length > 0 && resources.length > 0 &&
                  <>Showing {resources.length} of {pager.total_results_count} Results</>
                }

                {!resources.length &&
                  <>No Results</>
                }
              </FilteredResourcesHeadingDefault>

              {Object.keys(filters).length > 0 &&
                <AppliedFiltersWrapper onClick={handleAppliedFilterClick}>
                  {resourceFacets && resourceFacets.map((category, i) => (
                      category.options && category.options.map((option, i) => {
                          if (category.id in filters && filters[category.id].indexOf(option.id) !== -1) {
                            return (<AppliedFilter className={'resources-filter-category-option-selected'} key={i}>{option.name}
                              <RemoveIndividualFilter className={'resources-filter-category-option-remove'} data-category-id={category.id} data-id={option.id}/>
                            </AppliedFilter>)
                          }
                        }
                      )
                    )
                  )}
                  <ClearAllFilters onClick={handleClearAllFilters}>Clear All</ClearAllFilters>
                </AppliedFiltersWrapper>
              }

              {!resources.length &&
                <NoResultsWrapper>
                  <NoResultsInfo>
                    Sorry. We’re coming up short, but we’re here to support you. Feel free to contact us for additional help.
                  </NoResultsInfo>
                  <ButtonWrapper>
                    <Button text={'Contact Us'} link={'#'}/>
                  </ButtonWrapper>
                </NoResultsWrapper>
              }
            </FilteredResourcesHeadingWrapper>

            <FilteredResourcesResults id={'resources'}>

              {resources.length > 0 &&
                <InfiniteScroll
                  dataLength={resources.length}
                  next={loadMoreResources}
                  hasMore={loadMore}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="resources"
                >
                  <FilteredResourcesResultsList>
                    {resources && resources.map((el, i) => {
                      return (
                        <FilteredResourcesLink href={el.paragraphType === 'external_resource' ? el.link : `/resources${el.url}`} target={el.paragraphType === 'external_resource' ? '_blank' : '_self'} key={i}>
                          <FilteredResources>
                            <OrbImageWrapper>
                              <OrbImage type={el.theme}/>
                            </OrbImageWrapper>
                            <FilteredResourcesUpper>
                              <FilteredResourcesSource>
                                {el.source}
                              </FilteredResourcesSource>
                              <FeaturedResourceHeading>{el.heading}</FeaturedResourceHeading>
                            </FilteredResourcesUpper>
                            <FilteredResourcesBottom>
                              <FilteredResourcesType>{el.type}</FilteredResourcesType>
                            </FilteredResourcesBottom>
                          </FilteredResources>
                        </FilteredResourcesLink>
                      )
                    })}
                  </FilteredResourcesResultsList>
                </InfiniteScroll>
              }
            </FilteredResourcesResults>


          </FilteredResourcesContainer>
        </FilteredResourcesWrapper>
      </ResourceListingWrapper>
    </Layout>
  )
}

export default ResourceListing

export { Head } from '../components/_GatsbyPageHead'

// Note: Workaround on Gatsby issue, for some reason these queries (Taxonomy related) cannot live
// inside a folder like utils/queries (side effects on query), hence the use
// of facet queries here.
export const query = graphql`
  query($NodeId: String!, $ResourceTopic: Int!, $RelatedResourcesId: String!) {
    ...FeaturedContentRow
    ...RelatedResources
    ...DynamicResources
    allTaxonomyTermResourceAudience {
      nodes {
        drupal_internal__tid
        name
      }
    }
    allTaxonomyTermResourceContentTypes {
      nodes {
        drupal_internal__tid
        name
      }
    }
    allTaxonomyTermResourceGoalsTopics {
      nodes {
        drupal_internal__tid
        name
      }
    }
    allTaxonomyTermResourceTopics {
      nodes {
        drupal_internal__tid
        name
      }
    }
  }
`

const ResourceListingWrapper = styled.section`
  align-items: center;
  display: ${props => props.showMobileFilters ? 'none' : 'flex'};
  flex-direction: column;
  max-inline-size: 1440px;
  margin: auto;
  padding-inline: 20px;
  padding-block: 40px;

  ${mq.tablet} {
    display: flex;
    padding-inline: 80px;
    padding-block: 60px;
  }
`

const ResourceListingHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  margin-block-end: 20px;
  text-align: left;
  width: 100%;

  ${mq.tablet} {
    font-size: 40px;
  }
`

const ResourceListingMainContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-items: left;
  margin-block-end: 20px;

  ${mq.tablet} {
    margin-block-end: 40px;
  }
`

const ResourceListingMainContent = styled.div`
  flex-basis: unset;

  ${mq.tablet} {
    flex-basis: 50%;
  }
`

const FeaturedResourceUpper = styled.div``

const FeaturedResourceBottom = styled.div`
  display: flex;
  max-inline-size: 250px;
  flex-direction: column;
`

const FeaturedResourceContainer = styled.div`
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  margin-block-end: 20px;
  width: 100%;

  ${mq.tablet} {
    margin-block-end: 40px;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const FeaturedResource = styled.div`
  background-color: ${colors.white};
  background-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 183px;
  padding: 40px 30px;
  overflow: hidden;
  position: relative;

  ${mq.tablet} {
    max-inline-size: 400px;
    margin: auto;
    background-size: 100px;
    min-block-size: 271px;
  }
`

const OrbImageWrapper = styled.div`
  width: 90px;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(35%, 45%);
  z-index: 1;
  transition: all 0.4s ease-in-out;

  ${mq.tablet} {
    width: 180px;
  }
`

const FeaturedResourceHeading = styled.h3`
  color: ${colors.black};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;
  margin-block-end: 20px;

  ${mq.tablet} {
    font-size: 23px;
  }
`

const FeaturedResourceLink = styled.a`
  text-decoration: none;
  width: 100%;
  max-width: unset;
  flex: unset;

  ${mq.tablet} {
    max-width: 400px;
    flex: 1 1 29%;
  }

  &:hover {
    ${OrbImageWrapper} {
      transform: translate(35%, 45%) scale(1.2) rotateZ(15deg);
    }

    ${FeaturedResourceHeading} {
      color: ${colors.primary}
    }
  }
`

const FeaturedResourceSource = styled.h6`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 13px;
  margin-block-end: 5px;
`

const FeaturedResourceType = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 12px;
`

const FeaturedResourceGoalLabel = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 12px;
`

const ResourceListingSeparator = styled.div`
  margin-block: 60px;
  width: 100%;
  border-top: 1px solid #bcbdbd;
`

const FilteredResourcesWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  margin-bottom: 80px;
  position: relative;

  ${mq.tablet} {
    grid-template-columns: 0.25fr auto;
    grid-template-rows: 1fr;
  }
`
const ResourcesFilterButton = styled.div`
  width: 100%;
  height: 50px;
  border: solid 2px ${colors.gray};
  display: grid;
  grid-template-columns: auto 50px;
  padding: 4px 9px;
  line-height: 1.5;
  background-color: ${colors.white};
  align-content: center;
  padding: 0px 20px;

  ${mq.tablet} {
    display: none;
  }
`

const ResourcesFilterButtonIcon = styled.div`
  width: 50px;
  height: 18px;
  align-self: center;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background-image: url(${filter});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
`

const ResourcesFilterContainer = styled.div`
  width: 100%;
  min-width: 290px;
  display: none;

  ${mq.tablet} {
    display: block;
  }
`

const FilteredResourcesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`

const ResourcesFilterHeading = styled.p`
  font-weight: bold;
`

const ResourcesFilters = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-block: 30px;
  row-gap: unset;
  padding-inline: 20px;

  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px - 128px);

  ${mq.tablet} {
    padding-inline: unset;
    overflow-y: unset;
    overflow-x: unset;
    height: auto;
    row-gap: 25px;
  }
`

const ResourcesFilterCategory = styled.div`
  display: grid;
  grid-template-rows: 50px auto;
  width: 100%;
  align-items: center;
`
const ResourcesFilterCategoryNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  width: 100%;
  height: 100%;
  align-items: center;
  border-bottom: unset;

  ${mq.tablet} {
    border-bottom: 1px solid #bcbdbd;
  }
`

const ResourcesFilterCategoryName = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-family: "BuckeyeSans";
  font-weight: 600;

  ${mq.tablet} {
    font-size: 19px;
  }
`

const ResourcesFilterCategoryExpandButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: "\\002B";
    position: absolute;
    right: 0px;
    top: 15px;
    font-size: 28px;
    transform: rotate(90deg);
    transition: transform 0.3s linear;
  }

  &.toggle-expand-button {

    &::before {
      content: '\\2013';
      position: absolute;
      right: 4px;
      top: 15px;
      font-size: 27px;
      transform: none;
      transition: content, transform, 0.25s;
    }
  }
`
const ResourcesFilterCategoryOptions = styled.div`
  flex-wrap: wrap;
  margin-block: unset;
  display: none;
  grid-row-gap: 8px;

  &.toggle-expand-body {
    display: flex;
  }

  ${mq.tablet} {
    margin-block: 15px;
  }
`

const ResourcesFilterCategoryOption = styled.p`
  width: 100%;
  cursor: pointer;
  font-weight: 400;

  &.selected {
    font-weight: 800;
  }

  &.hidden {
    display: none;
  }
`

const FilteredResourcesHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`

const FilteredResourcesHeadingDefault = styled.p`
  font-size: 33px;
  font-weight: 600;
  line-height: 1.24;
`

/**  Filtered Resources  **/

const FilteredResourcesResults = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-block-size: 1340px;
  overflow-y: auto;
`

const FilteredResourcesResultsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`

const FilteredResourcesLink = styled.a`
  text-decoration: none;
  width: 100%;
  flex: unset;

  &:hover {
    ${OrbImageWrapper} {
      transform: translate(35%, 45%) scale(1.2) rotateZ(15deg);
    }

    ${FeaturedResourceHeading} {
      color: ${colors.primary}
    }
  }
`

const FilteredResources = styled.div`
  background-color: ${colors.white};
  background-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 183px;
  padding: 40px 30px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq.tablet} {
    margin: auto;
    background-size: 100px;
    min-block-size: 206px;
  }
`

const FilteredResourcesUpper = styled.div``

const FilteredResourcesBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const FilteredResourcesSource = styled.h6`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 13px;
  margin-block-end: 5px;
`

const FilteredResourcesType = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 12px;
`

const FilteredResourcesGoalLabel = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 12px;
`

const NoResultsInfo = styled.p``

const ButtonWrapper = styled.div``

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
`

const AppliedFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`

const AppliedFilter = styled.div`
  display: flex;
  padding: 4px 9px;
  line-height: 1.5;
  background-color: ${colors.white};
`

const RemoveIndividualFilter = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: "\\0078";
    position: absolute;
    right: 0px;
    top: -4px;
    font-size: 20px;
  }
`

const ClearAllFilters = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  line-height: 1.5;
  cursor: pointer;
  font-size: 14px;

  ${mq.tablet} {
    font-size: 16px;
  }
`

const ViewMoreFilters = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.primary};
  line-height: 1.5;
  cursor: pointer;

  ${mq.tablet} {
    font-size: 16px;
  }

  &.hidden {
    display: none;
  }
`

const ResourcesFilterMobile = styled.div`
  position: fixed;
  display: ${props => props.showMobileFilters ? 'flex' : 'none'};
  flex-direction: column;
  width: 100%;
  background-color: white;
  top: 0px;
  z-index: 10;
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;

  ${mq.tablet} {
    display: none
  }
`

const ResourcesFilterMobileHeading = styled.div`
  width: 100%;
  height: 60px;
  border-bottom: solid 1px #a6a7a8;
  display: grid;
  grid-template-columns: 50px auto 50px;
  padding: 4px 9px;
  line-height: 1.5;
  background-color: ${colors.white};
  align-content: center;
  padding: 0px 20px;

  ${mq.tablet} {
    display: none;
  }
`

const ResourcesFilterMobileHeadingTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  grid-column-start: 2;
`

const ResourcesFilterMobileCloseButtonIcon = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: "\\0078";
    position: absolute;
    right: 0px;
    font-size: 28px;
  }
`

const ResourcesFilterMobileButtonsWrapper = styled.div`
  border-top: solid 1px #a6a7a8;
  width: 100%;
  height: 128px;
  padding: 20px;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
`

const ClearAndResultsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const ResourcesFilterMobileResults = styled.p`
  font-size: 14px;
  line-height: 1.5;
`

const ShowMyResultsButton = styled.div`
  background: linear-gradient(to left, #4ba492, #26686d);
  color: ${colors.white};
  display: inline-block;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    background: linear-gradient(to right, #4ba492, #26686d);
  }
`
