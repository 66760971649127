import React from 'react'
import styled, { keyframes } from 'styled-components'
import { mq } from '../../styles/theme'

const Preloader = ({ containerHeight }) => {

  // Avoiding Gatsby warnings that lead to PROD console errors (not critical)
  // Gatsby link will only work for internal urls, not external
  return (
    <>
      <PreloaderContainer containerHeight={containerHeight}><Ring><RingElem /><RingElem /><RingElem /><RingElem /></Ring></PreloaderContainer>
    </>
  )
}

export default Preloader

const PreloaderContainer = styled.div`
  block-size: ${({ containerHeight }) => containerHeight || 'calc(100% - 60px)'};
  position: absolute;
  inset: ${({ containerHeight }) => containerHeight ? '0' : '60px'} 0 0 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq.tablet} {
    block-size: ${({ containerHeight }) => containerHeight || 'calc(100% - 100px)'};
    inset: ${({ containerHeight }) => containerHeight ? '0' : '100px'} 0 0 0;
  }
`

const ringAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const RingElem = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: ${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4ba492 transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`

const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  ${RingElem} {
    &:nth-child(4) {
      animation-delay: 0s;
    }
  }
`
