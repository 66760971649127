import React from 'react'
import styled from 'styled-components'
import PhoneIcon from '../../assets/phone.svg'
import { colors } from '../../styles/theme'

const ContactLink = ({ text, phone }) => {
  return (
    <ContactContainer>
      <Icon src={PhoneIcon}/>
      {text} <Contact href={`tel:${phone}`}>{phone}</Contact>
    </ContactContainer>
  )
}

export default ContactLink

const ContactContainer = styled.span`
  align-items: center;
  display: flex;
  padding-block-start: 30px;
`

const Contact = styled.a`
  color: ${colors.primary};
  margin-inline-start: 5px;
  text-decoration: none;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background: ${colors.primary};
    transition: width .3s;
  }

  &:hover::after{
    width: 100%;
  }
`

const Icon = styled.img`
  margin-inline-end: 12px;
`
